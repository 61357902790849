const SolutionCardData = [
  {
    src: "Images/services-converted/ecommerce.png",
    info: "E-commerce",
    pricing: " Average Pricing: ₹80000",
    link: "/solutions/ecommerce",
  },
  {
    src: "Images/services-converted/website.png",
    info: "Web Development",
    pricing: " Average Pricing: ₹1600/hr.",
    link: "/solutions/web-development",
  },
  {
    src: "Images/services-converted/app.png",
    info: "Mobile applications",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/mobile-application",
  },
  {
    src: "Images/services-converted/BI.png",
    info: "Business Intelligence & Data Warehousing",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/bi-data-warehousing",
  },
  {
    src: "Images/services-converted/staff-augmentation.png",
    info: "Staff Augmentation",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/staff-augmentation",
  },
  {
    src: "Images/services-converted/security.png",
    info: "Security",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/security",
  },
  {
    src: "Images/services-converted/cloud.png",
    info: "Cloud Services",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/cloud-services",
  },
  {
    src: "Images/services-converted/iot.png",
    info: "Internet of Things",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/iot",
  },
  {
    src: "Images/services-converted/ai.png",
    info: "AI & Automation",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/ai-automation",
  },
  {
    src: "Images/services-converted/PE.png",
    info: "Product Engineering",
    pricing: "Average Pricing: ₹1600/hr.",
    link: "/solutions/product-engineering",
  },
];

export default SolutionCardData;
