import React from 'react';
import { Box, Typography } from '@mui/material';

const ProductEngineering = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Driving Innovation through Advanced Product Engineering Solutions
      </Typography>
      
      <p>In today's fast-paced technological landscape, product engineering is the cornerstone of innovation and market leadership. At Logictrix Infotech, we're passionate about transforming ideas into reality, delivering cutting-edge product engineering solutions that help businesses stay ahead in the competitive digital arena.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Power of Product Engineering
      </Typography>      
      <p>Product engineering is reshaping industries across the board. With the global engineering services outsourcing market expected to reach $1.6 trillion by 2025, the opportunities for innovation are boundless. Whether you're a startup or an established corporation, leveraging advanced product engineering can:</p>
      
      <ul>
        <li>Accelerate time-to-market for new products</li>
        <li>Enhance product quality and performance</li>
        <li>Reduce development costs and risks</li>
        <li>Drive innovation and differentiation in the market</li>
        <li>Improve user experience and customer satisfaction</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's Product Engineering Services
      </Typography>
      
      <p>At Logictrix Infotech, we don't just develop products; we engineer solutions that drive business growth and technological advancement. Our product engineering offerings include:</p>
      
      <ol>
        <li><strong>New Product Development:</strong> We transform your ideas into market-ready products, handling everything from concept to launch.</li>
        <li><strong>Product Re-engineering:</strong> We modernize and optimize existing products to enhance performance, reduce costs, and extend product lifecycles.</li>
        <li><strong>Embedded Systems Development:</strong> Our experts design and develop robust embedded systems for various industries and applications.</li>
        <li><strong>IoT Product Development:</strong> We create innovative IoT products that connect, collect data, and provide actionable insights.</li>
        <li><strong>Software Product Engineering:</strong> We develop scalable, secure, and user-friendly software products tailored to your business needs.</li>
        <li><strong>Quality Assurance and Testing:</strong> We ensure product reliability and performance through comprehensive testing and quality assurance processes.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our Product Engineering Process
      </Typography>
      <ol>
        <li><strong>Requirements Analysis:</strong> We work closely with you to understand your vision, market needs, and technical requirements.</li>
        <li><strong>Conceptualization and Design:</strong> Our team creates detailed product designs and prototypes, ensuring alignment with your goals.</li>
        <li><strong>Development:</strong> We employ agile methodologies to develop your product, ensuring flexibility and continuous improvement.</li>
        <li><strong>Testing and Quality Assurance:</strong> We conduct rigorous testing to ensure product quality, performance, and reliability.</li>
        <li><strong>Deployment:</strong> We manage the product launch process, ensuring a smooth transition to the market.</li>
        <li><strong>Maintenance and Support:</strong> We provide ongoing support and updates to keep your product competitive and relevant.</li>
        <li><strong>Continuous Innovation:</strong> We help evolve your product, incorporating new technologies and features to meet changing market demands.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for Product Engineering?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> Our team of engineers brings deep expertise across various technologies and industries.</li>
        <li><strong>Innovation Focus:</strong> We stay at the forefront of technological advancements, incorporating cutting-edge solutions into our product engineering processes.</li>
        <li><strong>End-to-End Services:</strong> From ideation to launch and beyond, we provide comprehensive product engineering services.</li>
        <li><strong>Quality Commitment:</strong> We adhere to the highest quality standards, ensuring robust, reliable, and high-performance products.</li>
        <li><strong>Time and Cost Efficiency:</strong> Our streamlined processes and experienced team help reduce time-to-market and development costs.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Engineer Your Next Breakthrough Product?
      </Typography>
      
      <p>In today's technology-driven world, innovative products are the key to business success and market leadership. At Logictrix Infotech, we're excited to partner with you in bringing your product vision to life, leveraging our expertise to create solutions that not only meet current needs but also anticipate future market trends.</p>
      
      <p>Don't let your product ideas remain unrealized. Contact Logictrix Infotech today, and let's explore how our product engineering services can transform your concepts into market-leading products that drive your business forward.</p>
      
      <p>Remember, in the world of product engineering, every challenge is an opportunity for innovation. With Logictrix Infotech, you're not just developing a product—you're engineering the future of your industry!</p>
    </Box>
  );
};

export default ProductEngineering;