import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const StaffAugmentation = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h3" sx={{ marginBottom: '20px', color: '#2c3e50' }}>
        Enhancing Your Workforce: Staff Augmentation Solutions
      </Typography>
      
      <p>In today’s dynamic business environment, the ability to scale your team quickly and efficiently is crucial. At Logictrix Infotech, we offer top-tier staff augmentation services that empower your business with the skilled professionals you need, precisely when you need them.</p>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        The Benefits of Staff Augmentation
      </Typography>      
      
      <p>Staff augmentation provides a strategic approach to addressing your workforce needs. Here’s why it’s a smart choice:</p>
      
      <ul>
        <li>Flexibility to scale your team up or down based on project requirements</li>
        <li>Access to a global talent pool with specialized skills</li>
        <li>Reduced hiring and training costs</li>
        <li>Faster onboarding of skilled professionals</li>
        <li>Enhanced focus on core business functions</li>
      </ul>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Our Staff Augmentation Services
      </Typography>
      
      <p>At Logictrix Infotech, we provide a comprehensive range of staff augmentation services tailored to meet your unique business needs:</p>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>IT Staff Augmentation</Typography>
          <p>Bolster your IT team with experienced professionals in software development, network administration, and more.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Project-Based Augmentation</Typography>
          <p>Get the right talent for your specific project needs, ensuring timely delivery and quality outcomes.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>On-Demand Specialists</Typography>
          <p>Access specialists with niche skills on an as-needed basis to tackle complex challenges.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Long-Term Augmentation</Typography>
          <p>Integrate long-term contract professionals into your team to support ongoing projects and operations.</p>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Our Staff Augmentation Process
      </Typography>
      
      <ol>
        <li><strong>Needs Assessment:</strong> We work closely with you to understand your business goals, project requirements, and skill gaps.</li>
        <li><strong>Talent Sourcing:</strong> Leveraging our extensive network, we identify and recruit top talent that matches your specific needs.</li>
        <li><strong>Screening and Selection:</strong> Rigorous screening processes ensure we provide professionals who are not only highly skilled but also a great cultural fit for your team.</li>
        <li><strong>Onboarding:</strong> We facilitate a smooth onboarding process to integrate the augmented staff into your team seamlessly.</li>
        <li><strong>Ongoing Support:</strong> Continuous support and performance monitoring to ensure the success of the engagement.</li>
      </ol>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Why Choose Logictrix Infotech for Staff Augmentation?
      </Typography>
      
      <ul>
        <li><strong>Extensive Talent Network:</strong> Access to a vast pool of highly skilled professionals across various domains.</li>
        <li><strong>Customized Solutions:</strong> Tailored staff augmentation services to meet your specific business needs and objectives.</li>
        <li><strong>Proven Track Record:</strong> Successful history of helping businesses scale their teams and achieve their goals.</li>
        <li><strong>Seamless Integration:</strong> Ensuring smooth integration of augmented staff with your existing team.</li>
        <li><strong>Cost-Effective:</strong> Optimize your staffing costs with flexible, scalable solutions.</li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Ready to Enhance Your Workforce?
      </Typography>
      
      <p>In the fast-paced business world, having the right talent at the right time is crucial for success. At Logictrix Infotech, we’re committed to providing you with top-notch staff augmentation services that help you achieve your business objectives without the overhead of traditional hiring processes.</p>
      
      <p>Don’t let resource constraints hold you back. Contact Logictrix Infotech today, and let’s work together to build a powerful, agile, and efficient team that drives your business forward.</p>
      
      <p>Remember, with Logictrix Infotech, you're not just adding to your team—you're enhancing your capabilities and paving the way for future success!</p>
    </Box>
  );
};

export default StaffAugmentation;
