import React from "react";
import { Link } from "react-router-dom";

const SolutionCard = (props) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6">
      <Link to={props.link} className="card-link">
        <div className="card">
          <img src={props.cardImgSrc} alt="cardImage" />
          <div className="cardInfoBox">
            <p className="info">{props.info}</p>
            <p className="info">{props.pricing}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SolutionCard;