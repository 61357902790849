import React from "react";

const OurWorkCards = (props) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="card">
        <img src={props.posterSrc} className="backgroundImg" alt="poster" />
        <div className="cardInfo">
          <img src={props.logoSrc} className="brandLogo" alt="BrandLogo" />
          <p className="text">
            <strong>{props.brandName}</strong>
            {props.info}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurWorkCards;
