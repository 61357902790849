import React from 'react';
import { Box, Typography } from '@mui/material';

const Ecommerce = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Transforming Your Business with Cutting-Edge E-commerce Solutions
      </Typography>
      
      <p>In today's digital age, having a strong online presence is no longer optional - it's essential. At Logictrix Infotech, we specialize in creating powerful, user-friendly e-commerce solutions that help businesses thrive in the competitive online marketplace. Let's explore how our e-commerce expertise can revolutionize your business.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why E-commerce Matters
      </Typography>      
      <p>E-commerce has transformed the way businesses operate and consumers shop. With global e-commerce sales projected to reach $6.5 trillion by 2023, the opportunities are immense. Whether you're a small startup or an established enterprise, a robust e-commerce platform can:</p>
      
      <ul>
        <li>Expand your market reach</li>
        <li>Increase sales and revenue</li>
        <li>Enhance customer experience</li>
        <li>Provide valuable customer insights</li>
        <li>Reduce operational costs</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's E-commerce Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just build online stores; we create digital experiences that drive conversions and foster customer loyalty. Our e-commerce solutions are:</p>
      
      <ol>
        <li><strong>Customized to Your Needs:</strong> We understand that every business is unique. Our team works closely with you to develop an e-commerce platform that aligns with your brand identity and business goals.</li>
        <li><strong>User-Friendly:</strong> We prioritize intuitive navigation and seamless user experience, making it easy for customers to find and purchase your products.</li>
        <li><strong>Mobile-Responsive:</strong> With mobile commerce on the rise, we ensure your e-commerce site looks and functions flawlessly across all devices.</li>
        <li><strong>Secure:</strong> We implement robust security measures to protect your and your customers' sensitive information, building trust and credibility.</li>
        <li><strong>Scalable:</strong> Our solutions are designed to grow with your business, easily accommodating increased traffic and product ranges.</li>
        <li><strong>Integrated:</strong> We seamlessly integrate your e-commerce platform with other business systems like CRM, ERP, and inventory management for streamlined operations.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our E-commerce Development Process
      </Typography>
      <ol>
        <li><strong>Discovery:</strong> We start by understanding your business, target audience, and specific requirements.</li>
        <li><strong>Design:</strong> Our designers create visually appealing, brand-aligned layouts that enhance user engagement.</li>
        <li><strong>Development:</strong> Using cutting-edge technologies, we build a robust, feature-rich e-commerce platform.</li>
        <li><strong>Testing:</strong> Rigorous testing ensures your site functions flawlessly before launch.</li>
        <li><strong>Launch:</strong> We manage the deployment process, ensuring a smooth transition to your new e-commerce platform.</li>
        <li><strong>Support:</strong> Our relationship doesn't end at launch. We provide ongoing support and maintenance to keep your e-commerce site running smoothly.</li>
      </ol>


      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for Your E-commerce Needs?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> With years of experience in e-commerce development, we have the knowledge and skills to tackle any challenge.</li>
        <li><strong>Innovation:</strong> We stay ahead of e-commerce trends, incorporating the latest technologies and best practices into our solutions.</li>
        <li><strong>Results-Driven:</strong> Our focus is on creating e-commerce platforms that drive real business results - increased traffic, higher conversion rates, and improved customer satisfaction.</li>
        <li><strong>Comprehensive Service:</strong> From conceptualization to post-launch support, we're with you every step of the way.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Elevate Your Online Business?
      </Typography>
      
      <p>In the digital era, a powerful e-commerce presence is your gateway to unlimited growth potential. At Logictrix Infotech, we're passionate about helping businesses harness the power of e-commerce to reach new heights.</p>
      
      <p>Don't let outdated or underperforming e-commerce solutions hold your business back. Contact Logictrix Infotech today, and let's discuss how we can transform your online presence and boost your bottom line.</p>
      
      <p>Remember, in the world of e-commerce, the right solution can make all the difference. Choose Logictrix Infotech - where innovation meets results!</p>
    </Box>
  );
};

export default Ecommerce;
