import React, { Component } from "react";
import OurWorkCards from "../components/cards/OurWorkCards";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

export default class OurWork extends Component {
  render() {
    const cardData = [
      {
        posterSrc: "/Images/our_work/oyo-banquet.png",
        logoSrc: "/Images/our_work/w-oyo.png",
        brandName: "",
        info: "A seamless booking platform for weddings and events.",
      },
      {
        posterSrc: "/Images/our_work/surat-store.png",
        logoSrc: "/Images/our_work/w-suratstore.png",
        brandName: "",
        info:
          "E-commerce platform for localvendors to deliver grocery safely during lockdown.",
      },
      {
        posterSrc: "/Images/our_work/johnnytailor.png",
        logoSrc: "/Images/our_work/w-johnnytailor.png",
        brandName: "",
        info:
          "App that uses AI & ML to take your measurements which delivers you customized clothes.",
      },
      {
        posterSrc: "/Images/our_work/mitron.png",
        logoSrc: "/Images/our_work/w-mitron.png",
        info: "Short video-making application(rival for TikTok).",
      },
      {
        posterSrc: "/Images/our_work/bobbin.png",
        logoSrc: "/Images/our_work/w-bobbin.png",
        info:
          "App that provides scan and color detection feature to find exactmatch your machinery requirements and just-in-time delivery.",
      },
    ];
    return (
      <>
        <main>
          <div className="ourWork">
            <section className="homeBannerCommon">
              <div className="w-100">
                <h3 className="bannerHeading">Our Work Portfolio</h3>
                <p className="bannerText">
                  We take pride in sharing our success stories.
                </p>
              </div>
            </section>

            <section className="work">
              <div className="container-fluid">
                <div className="row gy-3">
                  {cardData.map((data, index) => (
                    <OurWorkCards
                      key={index}
                      posterSrc={data.posterSrc}
                      logoSrc={data.logoSrc}
                      brandName={data.brandName}
                      info={data.info}
                    />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </main>
        <Contact />
        <Footer />
      </>
    );
  }
}
