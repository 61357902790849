import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar";
import Home from "./containers/Home";
import ContactUs from "./containers/ContactUs";
import Career from "./containers/Career";
import About from "./containers/About";
import OurWork from "./containers/OurWork";
import Solution from "./containers/Solution";
import NordJobDetail from "./components/jobDetail/NordJobDetail";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsConditions from "./containers/Terms&Conditions";
import AiAutomation from "./containers/SolutionDetails/AiAutomation";
import BusinessIntelligence from "./containers/SolutionDetails/BusinessIntelligence";
import CloudServices from "./containers/SolutionDetails/CloudServices";
import Ecommerce from "./containers/SolutionDetails/Ecommerce";
import Iot from "./containers/SolutionDetails/Iot";
import MobileApplication from "./containers/SolutionDetails/MobileApplication";
import ProductEngineering from "./containers/SolutionDetails/ProductEngineering";
import Security from "./containers/SolutionDetails/Security";
import StaffAugmentation from "./containers/SolutionDetails/StaffAugmentation";
import WebDevelopment from "./containers/SolutionDetails/WebDevelopment";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

class App extends Component {
  render() {
    return (
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path="/home" component={Home} />
          <Route exact path="/career" component={Career} />
          <Route path="/career/nodeJsForm" component={NordJobDetail} />
          <Route path="/contactus" component={ContactUs} />
          {/* <Route path="/blogs" component={Blogs} /> */}
          <Route path="/about" component={About} />
          <Route path="/ourwork" component={OurWork} />
          <Route path="/solution" component={Solution} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/Terms&Conditions" component={TermsConditions} />
          <Route path="/solutions/ecommerce" component={Ecommerce} />
          <Route path="/solutions/web-development" component={WebDevelopment} />
          <Route path="/solutions/mobile-application" component={MobileApplication} />
          <Route path="/solutions/bi-data-warehousing" component={BusinessIntelligence} />
          <Route path="/solutions/staff-augmentation" component={StaffAugmentation} />
          <Route path="/solutions/security" component={Security} />
          <Route path="/solutions/cloud-services" component={CloudServices} />
          <Route path="/solutions/iot" component={Iot} /> 
          <Route path="/solutions/ai-automation" component={AiAutomation} />     
          <Route path="/solutions/product-engineering" component={ProductEngineering} />
          <Redirect to="/home" />
        </Switch>
      </Router>
    );
  }
}

export default App;
