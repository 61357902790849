import React from 'react';
import { Box, Typography } from '@mui/material';

const AiAutomation = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Revolutionizing Industries with AI & Automation Solutions
      </Typography>
      
      <p>In an era of rapid technological advancement, Artificial Intelligence (AI) and Automation are no longer futuristic concepts—they're transforming businesses today. At Logictrix Infotech, we're at the forefront of this revolution, providing cutting-edge AI and Automation solutions that propel businesses into the future of efficiency and innovation.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Power of AI & Automation
      </Typography>      
      <p>AI and Automation are reshaping industries across the board. With the global AI market size projected to reach $190.61 billion by 2025, the potential for growth is immense. Whether you're a startup or an established corporation, leveraging AI and Automation can:</p>
      
      <ul>
        <li>Dramatically increase operational efficiency</li>
        <li>Enhance decision-making with data-driven insights</li>
        <li>Improve customer experiences through personalization</li>
        <li>Reduce errors and improve quality control</li>
        <li>Free up human resources for more strategic tasks</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's AI & Automation Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just implement AI and Automation; we craft intelligent solutions that drive tangible business outcomes. Our AI and Automation offerings include:</p>
      
      <ol>
        <li><strong>Machine Learning Models:</strong> We develop and deploy custom ML models that learn from your data to make predictions and automate decision-making processes.</li>
        <li><strong>Natural Language Processing:</strong> Our NLP solutions enable machines to understand, interpret, and generate human language, powering chatbots, sentiment analysis, and more.</li>
        <li><strong>Computer Vision:</strong> We create systems that can interpret and analyze visual information, enabling applications like facial recognition, object detection, and quality control.</li>
        <li><strong>Robotic Process Automation (RPA):</strong> Our RPA solutions automate repetitive tasks, increasing efficiency and reducing errors in business processes.</li>
        <li><strong>AI-Powered Analytics:</strong> We implement AI-driven analytics platforms that provide deep insights and predictive capabilities for informed decision-making.</li>
        <li><strong>IoT Integration:</strong> We combine AI with IoT to create smart systems that can autonomously monitor, analyze, and optimize operations in real-time.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our AI & Automation Development Process
      </Typography>
      <ol>
        <li><strong>Assessment:</strong> We analyze your business processes to identify areas where AI and Automation can make the most impact.</li>
        <li><strong>Strategy:</strong> We develop a tailored AI and Automation strategy aligned with your business goals and industry specifics.</li>
        <li><strong>Data Preparation:</strong> We collect, clean, and structure your data to ensure it's ready for AI processing.</li>
        <li><strong>Development:</strong> Our experts build custom AI models and automation workflows using cutting-edge technologies.</li>
        <li><strong>Testing:</strong> We rigorously test our solutions to ensure accuracy, reliability, and seamless integration with your existing systems.</li>
        <li><strong>Deployment:</strong> We carefully implement the solutions, ensuring minimal disruption to your operations.</li>
        <li><strong>Monitoring & Optimization:</strong> We continuously monitor performance and fine-tune our solutions for optimal results.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for AI & Automation?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> Our team of AI specialists and automation experts bring years of experience across various industries.</li>
        <li><strong>Customization:</strong> We don't believe in one-size-fits-all solutions. Each AI and Automation solution is tailored to your unique business needs.</li>
        <li><strong>Ethical AI:</strong> We prioritize responsible AI development, ensuring transparency, fairness, and privacy in all our solutions.</li>
        <li><strong>Scalability:</strong> Our solutions are designed to grow with your business, easily adapting to changing needs and increasing data volumes.</li>
        <li><strong>Continuous Innovation:</strong> We stay at the cutting edge of AI and Automation technologies, constantly incorporating the latest advancements into our solutions.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Embrace the AI & Automation Revolution?
      </Typography>
      
      <p>In today's fast-paced business environment, AI and Automation are no longer luxuries—they're necessities for staying competitive. At Logictrix Infotech, we're committed to helping businesses harness the full potential of these transformative technologies.</p>
      
      <p>Don't let your business fall behind in the AI and Automation race. Contact Logictrix Infotech today, and let's explore how we can revolutionize your operations, boost productivity, and drive innovation in your industry.</p>
      
      <p>Remember, the future belongs to those who embrace AI and Automation today. Choose Logictrix Infotech - where cutting-edge technology meets unparalleled expertise!</p>
    </Box>
  );
};

export default AiAutomation;