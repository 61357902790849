import React, { Component } from "react";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "../axios";

export default class Contactus extends Component {
  state = {
    inquiryData: {
      name: "",
      email: "",
      mobile: "",
      subject: "",
      message: "",
    },
    success: false,
  };

  onSubmitHandler = (values, { resetForm }) => {
    const data = values;
    axios.post("/InquiryData.json", data).then(() => {
      this.setState({ success: !this.state.success });
      resetForm();
    });
  };

  render() {
    return (
      <>
        <main>
          <div className="contactUs">
            <section className="homeBannerCommon">
              <div className="text-center">
                <h3 className="bannerHeading">
                  Looking To INNOVATE A Better Tomorrow With Us!!
                </h3>
                <p className="bannerText">Reach out to our Customer Service</p>
              </div>
            </section>

            <section className="contactUsForm">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-7">
                    <h3 className="formHeading">Inquiry Form</h3>
                    <Formik
                      initialValues={this.state.inquiryData}
                      onSubmit={this.onSubmitHandler}
                      enableReinitialize
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .trim()
                          .required("First name is required")
                          .min(2, "Name is too short")
                          .test(
                            "alphabets",
                            "only charactor is allowed",
                            (values) => {
                              return /^[a-zA-z\s]+$/.test(values);
                            }
                          ),
                        email: Yup.string()
                          .required("Email is required")
                          .matches(/^(?=.*[@])/, "@ is required in email"),
                        mobile: Yup.string()
                          .required("Mobile number is required")
                          .min(10, "plz enter 10 digit mobile number")
                          .max(10, "plz enter 10 digit mobile number"),
                        message: Yup.string().required("Message is required"),
                      })}
                    >
                      {(props) => {
                        let { touched, errors } = props;
                        return (
                          <Form action="">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="inputBox">
                                  <Field
                                    type="text"
                                    className="commonInput"
                                    name="name"
                                    placeholder="Name"
                                  />
                                  {errors["name"] && touched["name"] && (
                                    <span className="error">
                                      {errors["name"]}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="inputBox">
                                  <Field
                                    type="email"
                                    name="email"
                                    className="commonInput"
                                    placeholder="Email"
                                  />
                                  {errors["email"] && touched["email"] && (
                                    <span className="error">
                                      {errors["email"]}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="inputBox">
                                  <Field
                                    type="number"
                                    name="mobile"
                                    className="commonInput"
                                    placeholder="Number"
                                  />
                                  {errors["mobile"] && touched["mobile"] && (
                                    <span className="error">
                                      {errors["mobile"]}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="inputBox">
                                  <Field
                                    type="text"
                                    name="subject"
                                    className="commonInput"
                                    placeholder="Subject"
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="textAreaBox">
                                  <Field
                                    as="textarea"
                                    name="message"
                                    className="commonTextArea"
                                    placeholder="Message"
                                    rows="4"
                                  />
                                  {errors["message"] && touched["message"] && (
                                    <span className="error">
                                      {errors["message"]}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <button
                                  type="submit"
                                  className="button fullBlue"
                                >
                                  Send
                                </button>
                              </div>
                              <div className="col-12">
                                {this.state.success ? (
                                  <p
                                    style={{
                                      margin: "10px auto",
                                      display: "inline-block",
                                      fontSize: "1.8vh",
                                    }}
                                  >
                                    Your form has been successfully submitted
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                  <div className="col-lg-5">
                    <div className="infoBox">
                      <div className="innerBox">
                        <img
                          src="/Images/SVG/Logictrix Logo/Logictrix-Logo-Gray.svg"
                          alt=""
                          className="logo"
                        />
                      </div>
                      <div className="innerBox">
                        <p className="text">
                          If you would like to discuss your next project or for
                          future employment queries, please do not hesitate to
                          complete the enquiry form or send us an email at
                        </p>
                        <p className="text">
                          <a href="mailto:hello@logictrixinfotech.com">
                            <strong>hello@logictrixinfotech.com</strong>
                          </a>
                        </p>
                      </div>
                      <div className="innerBox">
                        <p className="text">
                          For Career enquiries please contact
                        </p>
                        <p className="text">
                          <a href="mailto:career@logictrixinfotech.com">
                            <strong>career@logictrixinfotech.com</strong>
                          </a>
                        </p>
                      </div>
                      <div className="innerBox">
                        <p className="text">Address</p>
                        <p className="text">
                          <a href="https://www.google.com/maps/place/Logictrix+Infotech+Pvt.+Ltd./@21.1550653,72.7617003,17z/data=!3m1!4b1!4m5!3m4!1s0x3be04d83eece38ef:0x4ddb64f12a405d6c!8m2!3d21.1550653!4d72.763889">
                            <strong>
                              A-602 Swastik Universal Building Beside Vallentine
                              Mall, Surat - Dumas Rd, New Magdalla, Surat,
                              Gujarat 395007
                            </strong>
                          </a>
                        </p>
                      </div>
                      <div className="innerBox">
                        <p className="text">Call</p>
                        <p className="text">
                          <a href="tel:8799622322">
                            <strong> 8799622322 </strong>
                          </a>
                        </p>
                      </div>
                      <div className="innerBox">
                        <p className="text">Connect</p>
                        <div className="socialBox">
                          <a href="https://www.facebook.com/LogictrixInfotech/">
                            <Logo
                              src="/Images/logo/ic_Facebook_dark.svg"
                              alt="facebook"
                            />
                          </a>
                          <a href="https://www.instagram.com/logictrixinfotech">
                            <Logo
                              src="/Images/logo/ic_instagram_dark.svg"
                              alt="facebook"
                            />
                          </a>
                          <a href="https://www.linkedin.com/company/logictrix-infotech/">
                            <Logo
                              src="/Images/logo/ic_linkedin_dark.svg"
                              alt="facebook"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
