import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "../../axios";
import Logo from "../Logo";
import Recaptcha from "react-recaptcha";
import { Callbacks } from "jquery";

export default class NodeJobDetail extends Component {
  state = {
    iunquiryData: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      message: "",
      recaptcha: undefined,
      file: undefined,
    },
    success: false,
  };

  onSubmitHandler = (values, { resetForm }) => {
    const data = values;
    axios
      .post("/nord-Job-Apply.json", data)
      .then(() => {
        this.setState({ success: !this.state.success });
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const validation = Yup.object().shape({
      firstname: Yup.string()
        .trim()
        .required("First name is required")
        .min(2, "Name is too short")
        .test("alphabets", "only charactor is allowed", (values) => {
          return /^[a-zA-z\s]+$/.test(values);
        }),
      lastname: Yup.string()
        .trim()
        .required("Last name is required")
        .min(2, "Name is too short")
        .test("alphabets", "only charactor is allowed", (values) => {
          return /^[a-zA-z\s]+$/.test(values);
        }),
      email: Yup.string()
        .required("Email is required")
        .matches(/^(?=.*[@])/, "@ is required in email"),
      mobile: Yup.string()
        .required("Mobile number is required")
        .min(10, "plz enter 10 digit mobile number")
        .max(10, "plz enter 10 digit mobile number"),
      message: Yup.string().required("Message is required"),
      file: Yup.mixed()
        .required("plz upload resume")
        .test(
          "fileFormat",
          "plz upload pdf file only",
          (value) => value && ["application/pdf"].includes(value.type)
        ),
      recaptcha: Yup.mixed().required("plz mark chaptcha"),
    });
    return (
      <>
        <div className="applyPost">
          <section className="homeBannerCommon">
            <div className="text-center">
              <h3 className="bannerHeading">Node Js Developer</h3>
            </div>
            <div className="logoBox">
              <Logo
                src="/Images/technology_logo/node_js_white.svg"
                alt="nordJs"
              />
            </div>
          </section>

          <section className="applyPostForm">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5">
                  <div className="requirementBox">
                    <div className="infoBox">
                      <h5 className="heading">Node Js Developer</h5>
                      <p className="info">
                        Logictrix Infotech Pvt. Ltd. provides high-end IT
                        solutions and tech-integration for all types of
                        businesses.
                      </p>
                      <p className="info">
                        We specialize in E-commerce, ERP systems and customised
                        software solutions.
                      </p>
                      <p className="info">
                        We are recognized by #startupindia in ‘Enterprise
                        Software’ industry and ‘Cloud’ sector.
                      </p>
                      <p className="info">
                        We are a team of highly skilled, committed and
                        enthusiastic developers, designers and marketers.
                      </p>
                      <p className="info">We believe in making-it-happen.</p>
                    </div>
                    <div className="infoBox">
                      <h6 className="listHeading">Required Skills:</h6>
                      <ul className="list">
                        <li className="listItem">NodeJs</li>
                        <li className="listItem">Javascript</li>
                        <li className="listItem">Algorithm</li>
                        <li className="listItem">Design Pattern</li>
                        <li className="listItem">MongoDb</li>
                      </ul>
                    </div>
                    <div className="infoBox">
                      <h6 className="listHeading">Job Detail:</h6>
                      <ul className="list">
                        <li className="listItem">Exp: 2-3 Years</li>
                        <li className="listItem">Location: Surat</li>
                        <li className="listItem">CTC: Till 3.6 LPA</li>
                      </ul>
                    </div>
                    <div className="infoBox">
                      <h6 className="listHeading">Desciption:</h6>
                      <ul className="list">
                        <li className="listItem">
                          Good to have: WebAPI, AzureColud, Azure Devops
                        </li>
                        <li className="listItem">
                          Industry-Type: IT-Software/ Software Services
                        </li>
                        <li className="listItem">
                          Functional Area: IT-Software Application Programming,
                          Maintenance
                        </li>
                        <li className="listItem">Role: Software Developer</li>
                      </ul>
                    </div>
                    <div className="infoBox">
                      <h6 className="listHeading">Employee Details:</h6>
                      <ul className="list">
                        <li className="listItem">
                          Company Name: Logictrix Infotech Pvt. Ltd.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Formik
                    initialValues={this.state.iunquiryData}
                    onSubmit={this.onSubmitHandler}
                    enableReinitialize
                    validationSchema={validation}
                  >
                    {(props) => {
                      let { touched, errors } = props;
                      return (
                        <Form className="applyForm">
                          <h3 className="formHeading">Apply Now</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="inputBox">
                                <Field
                                  type="text"
                                  name="firstname"
                                  className="commonInput"
                                  placeholder="First Name"
                                />
                                {errors["firstname"] &&
                                  touched["firstname"] && (
                                    <span className="error">
                                      {errors["firstname"]}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inputBox">
                                <Field
                                  type="text"
                                  name="lastname"
                                  className="commonInput"
                                  placeholder="Last Name"
                                />
                                {errors["lastname"] && touched["lastname"] && (
                                  <span className="error">
                                    {errors["lastname"]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inputBox">
                                <Field
                                  type="email"
                                  name="email"
                                  className="commonInput"
                                  placeholder="Email"
                                />
                                {errors["email"] && touched["email"] && (
                                  <span className="error">
                                    {errors["email"]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="inputBox">
                                <Field
                                  type="number"
                                  name="mobile"
                                  className="commonInput"
                                  placeholder="Phone Number"
                                />
                                {errors["mobile"] && touched["mobile"] && (
                                  <span className="error">
                                    {errors["mobile"]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="textAreaBox">
                                <Field
                                  as="textarea"
                                  name="message"
                                  className="commonTextArea"
                                  placeholder="Something about your self..."
                                  rows="4"
                                />
                                {errors["message"] && touched["message"] && (
                                  <span className="error">
                                    {errors["message"]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="inputBox">
                                <input
                                  type="url"
                                  className="commonInput"
                                  placeholder="Links to your Linkedin, Github, Bitbucket, Behance, Dribble..."
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="inputBox fileBox">
                                <div className="fileUploadBox">
                                  <label htmlFor="file" className="fileUpload">
                                    add Resume/CV
                                  </label>
                                  <p className="note">No file Choosen</p>
                                  <input
                                    name="file"
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    className="commonInput"
                                    placeholder="upload your resume"
                                    onChange={(event) => {
                                      props.setFieldValue(
                                        "file",
                                        event.target.files[0]
                                      );
                                    }}
                                  />
                                </div>
                                {errors["file"] && touched["file"] && (
                                  <span className="error">
                                    {errors["file"]}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="inputBox">
                                <Recaptcha
                                  className="g-recaptcha"
                                  sitekey="6LcwrCIaAAAAAFOJ0GBfFlAH7IpOYZAJCtt6GGPE"
                                  render="explicit"
                                  onloadCallback={Callbacks}
                                  verifyCallback={(response) => {
                                    props.setFieldValue("recaptcha", response);
                                    if (this.state.success) {
                                      this.verifyCallback(() => response);
                                    }
                                  }}
                                />
                                {errors["recaptcha"] &&
                                  touched["recaptcha"] && (
                                    <span className="error">
                                      {errors["recaptcha"]}
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-12">
                              <button type="submit" className="button fullBlue">
                                Apply
                              </button>
                            </div>
                            <div className="col-12">
                              {this.state.success ? (
                                <p style={{ margin: "10px 0 " }}>
                                  Your form has been successfully submitted
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
