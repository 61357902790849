import React from "react";
import { Link } from "react-router-dom";

const Contact = (props) => {
  return (
    <div className="contact" data-aos={props.aos}>
      <h4 className="contactTitle">
        Ready To Integrate Technology In Your Business?
      </h4>
      <Link to="/contactus" className="button fullBlue">
        Contact with us
      </Link>
    </div>
  );
};

export default Contact;
