import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const Security = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h3" sx={{ marginBottom: '20px', color: '#2c3e50' }}>
        Safeguarding Your Digital Assets: Comprehensive Security Solutions
      </Typography>
      
      <p>In today’s interconnected world, security is paramount. At Logictrix Infotech, we prioritize protecting your digital assets with robust security solutions that guard against threats and ensure your business continuity.</p>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        The Importance of Cybersecurity
      </Typography>      
      
      <p>Cybersecurity is more critical than ever. Here are some compelling reasons why it should be at the forefront of your business strategy:</p>
      
      <ul>
        <li>43% of cyberattacks target small businesses</li>
        <li>Cybercrime damages are predicted to cost the world $10.5 trillion annually by 2025</li>
        <li>60% of small businesses go out of business within six months of a cyberattack</li>
        <li>95% of cybersecurity breaches are due to human error</li>
      </ul>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Our Security Services
      </Typography>
      
      <p>At Logictrix Infotech, we offer a comprehensive range of security services tailored to meet the unique needs of your business:</p>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Network Security</Typography>
          <p>Protect your network from unauthorized access, misuse, or theft with our advanced network security solutions.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Data Encryption</Typography>
          <p>Ensure the confidentiality and integrity of your sensitive data with our robust encryption techniques.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Threat Detection and Response</Typography>
          <p>Stay ahead of cyber threats with our proactive detection and rapid response services.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Cloud Security</Typography>
          <p>Secure your cloud environments with our comprehensive cloud security solutions.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Endpoint Protection</Typography>
          <p>Protect all endpoints connected to your network with our cutting-edge endpoint security measures.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Compliance and Risk Management</Typography>
          <p>Ensure compliance with industry regulations and manage risks effectively with our expert guidance.</p>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Our Security Approach
      </Typography>
      
      <ol>
        <li><strong>Assessment and Planning:</strong> We conduct a thorough assessment of your current security posture and develop a strategic plan tailored to your needs.</li>
        <li><strong>Implementation:</strong> Our team implements cutting-edge security technologies and best practices to safeguard your digital assets.</li>
        <li><strong>Monitoring and Management:</strong> Continuous monitoring and management of your security infrastructure to detect and respond to threats in real-time.</li>
        <li><strong>Training and Awareness:</strong> Educating your team on cybersecurity best practices to minimize the risk of human error.</li>
        <li><strong>Incident Response:</strong> Rapid and effective response to any security incidents to minimize impact and restore normal operations.</li>
        <li><strong>Continuous Improvement:</strong> Regular reviews and updates to your security measures to stay ahead of evolving threats.</li>
      </ol>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Why Choose Logictrix Infotech for Security?
      </Typography>
      
      <ul>
        <li><strong>Expertise and Experience:</strong> Our team of security experts has extensive experience in protecting businesses of all sizes.</li>
        <li><strong>Comprehensive Solutions:</strong> We offer a wide range of security services to cover all aspects of your business.</li>
        <li><strong>Proactive Approach:</strong> Our proactive security measures help prevent incidents before they occur.</li>
        <li><strong>Customized Strategies:</strong> Tailored security strategies that align with your specific business needs and objectives.</li>
        <li><strong>24/7 Support:</strong> Round-the-clock support to ensure your business is always protected.</li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
        Ready to Secure Your Business?
      </Typography>
      
      <p>In the digital era, robust security is not just an option—it’s a necessity. At Logictrix Infotech, we’re dedicated to providing top-notch security solutions that protect your business from cyber threats and ensure your peace of mind.</p>
      
      <p>Don’t leave your digital assets vulnerable. Contact Logictrix Infotech today, and let us help you build a secure, resilient, and future-proof security infrastructure.</p>
      
      <p>Remember, in the world of cybersecurity, vigilance is key. With Logictrix Infotech, you're not just securing your business—you're fortifying your future!</p>
    </Box>
  );
};

export default Security;
