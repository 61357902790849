import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const WebDevelopment = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h3" sx={{ marginBottom: '20px', color: '#2c3e50' }}>
      Crafting Digital Experiences: Web Development Solutions
      </Typography>
      
      <p>In the digital age, your website is often the first point of contact between your business and potential customers. At Logictrix Infotech, we specialize in creating powerful, engaging web experiences that not only captivate visitors but also drive business growth and enhance your online presence.</p>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
      The Impact of Professional Web Development
      </Typography>      
      
      <p>A well-crafted website can be a game-changer for your business. Consider these statistics:</p>
      
      <ul>
        <li>94% of first impressions relate to your site's web design</li>
        <li>75% of consumers admit to judging a company's credibility based on their website design</li>
        <li>88% of online consumers are less likely to return to a site after a bad experience</li>
        <li>Websites that load within 5 seconds have 70% longer average sessions</li>
      </ul>
      
      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
      Our Web Development Expertise
      </Typography>
      
      <p>At Logictrix Infotech, we offer a comprehensive suite of web development services designed to meet your unique business needs:</p>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Custom Website Development</Typography>
          <p>Tailor-made websites that align perfectly with your brand identity and business goals.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>E-Commerce Solutions</Typography>
          <p>Robust online stores that provide seamless shopping experiences and drive sales.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Content Management Systems (CMS)</Typography>
          <p>User-friendly CMS implementations that empower you to manage your content effortlessly.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Progressive Web Apps (PWAs)</Typography>
          <p>Next-gen web applications that offer app-like experiences across all devices.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Web Application Development</Typography>
          <p>Scalable, feature-rich web applications that streamline business processes.</p>
        </Box>
        <Box sx={{ width: '48%', marginBottom: '20px' }}>
          <Typography variant="h6" sx={{ color: '#2980b9' }}>Website Maintenance and Support</Typography>
          <p>Ongoing support to ensure your website remains secure, up-to-date, and performant.</p>
        </Box>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
      Our Web Development Approach
      </Typography>
      
      <ol>
        <li><strong>Discovery and Planning:</strong> We dive deep into your business goals, target audience, and competition to craft a strategic web development plan.</li>
        <li><strong>Design and Prototyping:</strong> Our designers create visually stunning, user-centric designs and interactive prototypes for your approval.</li>
        <li><strong>Development:</strong> Using cutting-edge technologies, we bring the approved designs to life, ensuring responsive, fast-loading, and SEO-friendly websites.</li>
        <li><strong>Testing and Quality Assurance:</strong> Rigorous testing across devices and browsers to ensure a flawless user experience.</li>
        <li><strong>Launch and Deployment:</strong> Smooth transition from development to live environment, ensuring zero downtime.</li>
        <li><strong>Post-Launch Support:</strong> Continuous monitoring, updates, and support to keep your website running at peak performance.</li>
      </ol>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
      Why Choose Logictrix Infotech for Web Development?
      </Typography>
      
      <ul>
        <li><strong>Expertise Across Technologies:</strong> Proficiency in a wide range of web technologies and frameworks.</li>
        <li><strong>User-Centric Design:</strong> Focus on creating intuitive, engaging user experiences that drive conversions.</li>
        <li><strong>Performance Optimization:</strong> Websites optimized for speed, SEO, and cross-device compatibility.</li>
        <li><strong>Scalable Solutions:</strong> Future-proof websites that grow with your business needs.</li>
        <li><strong>Collaborative Approach:</strong> Transparent communication and involvement throughout the development process.</li>
      </ul>

      <Divider sx={{ my: 3 }} />

      <Typography variant="h4" sx={{ marginTop: '20px', color: '#34495e', fontWeight: 'bold' }}>
      Ready to Elevate Your Web Presence?
      </Typography>
      
      <p>In the digital landscape, your website is your most powerful marketing tool. At Logictrix Infotech, we're committed to helping you harness the full potential of web technology to create impactful online experiences that resonate with your audience and drive business growth.</p>
      
      <p>Don't settle for a mediocre web presence. Contact Logictrix Infotech today, and let's embark on a journey to transform your digital identity and set new standards in web excellence.</p>
      
      <p>Remember, in the world of web development, every pixel counts. With Logictrix Infotech, you're not just building a website—you're crafting a digital masterpiece that speaks volumes about your brand!</p>
    </Box>
  );
};

export default WebDevelopment;