import React from 'react';
import { Box, Typography } from '@mui/material';

const MobileApplication = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Empowering Businesses with Cutting-Edge Mobile Applications
      </Typography>
      
      <p>In today's mobile-first world, a powerful mobile presence is no longer optional—it's essential for business success. At Logictrix Infotech, we're at the forefront of mobile app development, creating innovative, user-centric applications that drive engagement, boost productivity, and open new revenue streams for businesses across industries.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Mobile App Revolution
      </Typography>      
      <p>Mobile apps are reshaping how businesses interact with customers and streamline operations. With global mobile app revenues projected to reach $935 billion by 2023, the potential for growth is enormous. Whether you're a startup or an established enterprise, leveraging mobile apps can:</p>
      
      <ul>
        <li>Enhance customer engagement and loyalty</li>
        <li>Increase brand visibility and recognition</li>
        <li>Provide valuable data insights about user behavior</li>
        <li>Improve internal communication and productivity</li>
        <li>Create new revenue streams through in-app purchases or subscriptions</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's Mobile App Development Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just build apps; we craft digital experiences that resonate with users and drive business growth. Our mobile app offerings include:</p>
      
      <ol>
        <li><strong>Native App Development:</strong> We create high-performance, feature-rich apps for iOS and Android platforms using Swift, Kotlin, and Java.</li>
        <li><strong>Cross-Platform Development:</strong> Our experts use frameworks like React Native and Flutter to build cost-effective apps that work seamlessly across multiple platforms.</li>
        <li><strong>Progressive Web Apps (PWAs):</strong> We develop PWAs that combine the best of web and mobile apps, offering offline functionality and app-like experiences.</li>
        <li><strong>Enterprise Mobile Solutions:</strong> We build secure, scalable mobile apps that streamline business processes and boost employee productivity.</li>
        <li><strong>Mobile App UI/UX Design:</strong> Our designers create intuitive, visually appealing interfaces that ensure optimal user experiences.</li>
        <li><strong>IoT and Mobile Integration:</strong> We develop mobile apps that seamlessly interact with IoT devices, expanding your connected ecosystem.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our Mobile App Development Process
      </Typography>
      <ol>
        <li><strong>Discovery:</strong> We analyze your business goals, target audience, and market to define the app's scope and features.</li>
        <li><strong>Strategy:</strong> We develop a comprehensive mobile app strategy aligned with your business objectives and user needs.</li>
        <li><strong>Design:</strong> Our UI/UX experts create wireframes and prototypes, ensuring an intuitive and engaging user experience.</li>
        <li><strong>Development:</strong> Our skilled developers build your app using the latest technologies and best practices.</li>
        <li><strong>Testing:</strong> We conduct rigorous testing across devices and scenarios to ensure a bug-free, smooth user experience.</li>
        <li><strong>Deployment:</strong> We handle the app store submission process and ensure a smooth launch.</li>
        <li><strong>Maintenance & Updates:</strong> We provide ongoing support, monitoring app performance and rolling out updates to keep your app relevant and secure.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for Mobile App Development?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> Our team of mobile app developers brings years of experience across various industries and app categories.</li>
        <li><strong>User-Centric Approach:</strong> We prioritize user experience in every aspect of app design and development.</li>
        <li><strong>Cross-Platform Proficiency:</strong> We excel in both native and cross-platform development, choosing the best approach for your needs.</li>
        <li><strong>Scalability:</strong> Our apps are built to grow with your business, easily accommodating increasing users and features.</li>
        <li><strong>Security Focus:</strong> We implement robust security measures to protect user data and ensure compliance with regulations.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Transform Your Mobile Presence?
      </Typography>
      
      <p>In the mobile-driven digital landscape, a powerful app can be the differentiator that sets your business apart. At Logictrix Infotech, we're committed to helping businesses harness the full potential of mobile technology to engage customers, streamline operations, and drive growth.</p>
      
      <p>Don't let your business fall behind in the mobile revolution. Contact Logictrix Infotech today, and let's explore how we can create a mobile app that not only meets your current needs but also positions you for future success.</p>
      
      <p>Remember, in the world of mobile, every tap is an opportunity. With Logictrix Infotech, you're not just building an app—you're opening a new chapter of digital success for your business!</p>
    </Box>
  );
};

export default MobileApplication;