import React, { Component } from "react";
import TestimonialCard from "../components/cards/TestimonialCard";
import Lottie from "react-lottie";
import $ from "jquery";
import "slick-carousel/slick/slick";
import Aos from "aos";
import ReactFullpage from "@fullpage/react-fullpage";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Logo from "../components/Logo";

const FullPage = () => {
  const lottieDownArrow = {
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: "/Animations/Scroll Down.json",
    speed: 1,
  };

  const lottieBulb = {
    renderer: "svg",
    loop: false,
    autoplay: true,
    path: "/Animations/website homepage.json",
    speed: 1,
  };

  return (
    <ReactFullpage.Wrapper>
      <section className="home verticalSlide">
        <div className="innerBox">
          <div className="row">
            <div className="col-lg-6">
              <div className="contentBox">
                <h2 data-aos="fade-down" className="title">
                  Want to Grow Technologically? We will make it happen!
                </h2>
                <p data-aos="fade-up" className="text">
                  Cutting-edge technology for all your business solution. We
                  make sure your business vision is achieved.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="animationBox">
                <div className="homeBulb">
                  <Lottie options={lottieBulb} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="downArrowBox">
          <p className="text">Scroll Down</p>
          <div
            style={{
              width: "3vw",
              height: "3vh",
              background: "transparent",
            }}
          >
            <Lottie options={lottieDownArrow} />
          </div>
        </div>
      </section>

      <section className="verticalSlide white apps">
        <div className="row">
          <div className="col-lg-6">
            <div className="imageBox">
              <img
                src="/Images/laptop.png"
                alt="laptop"
                data-aos="fade-right"
              />
              <img
                src="/Images/google-nexus6p-portrait.png"
                className="posAbsol"
                alt="phone"
                data-aos="fade-up"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 d-flex align-items-center">
              <div className="contentBox">
                <h4 className="homeCommonTitle" data-aos="fade-left">
                  Software/ Apps/ Websites Name it! (apps you can’t pass by)
                </h4>
                <p
                  className="homeCommonText m-0"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  We build software solutions for all the operating system(OS).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="verticalSlide talk">
        <div className="row">
          <div className="col-lg-6">
            <div className="h-100 d-flex align-items-center">
              <div className="contentBox">
                <h4 className="homeCommonTitle" data-aos="fade-right">
                  Language? Talk {":programming"}
                </h4>
                <p
                  className="homeCommonText"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  We deliver your business solutions using high-end knowledge
                  engineering processes.
                </p>
                <div className="logoBox">
                  <img
                    src="/Images/technology_logo/python_white.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/react_white.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/angularjs_white.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/node_js_white.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/android-os_white.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="imageBox">
              <img
                src="/Images/talk-programming.svg"
                alt=""
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="verticalSlide white tech">
        <div className="row">
          <div className="col-lg-6">
            <div className="imageBox">
              <img
                src="/Images/ministry-technology.svg"
                alt=""
                data-aos="fade-right"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 d-flex align-items-center">
              <div className="contentBox">
                <h4 className="homeCommonTitle" data-aos="fade-left">
                  Ministry Of Technology
                </h4>
                <p
                  className="homeCommonText"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  Our team of experts deal with advanced technological
                  solutions.
                </p>
                <div className="logoBox">
                  <img
                    src="/Images/technology_logo/artificial_intelligence.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/machine_learning.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/cloud_computing.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="verticalSlide cloud">
        <div className="row">
          <div className="col-lg-6">
            <div className="h-100 d-flex align-items-center">
              <div className="contentBox">
                <h4 className="homeCommonTitle" data-aos="fade-right">
                  On our cloud
                </h4>
                <p
                  className="homeCommonText"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  We deal with all sorts of could computing services. Google
                  cloud platform AWS (all cloud services).
                </p>
                <div className="logoBox">
                  <img
                    src="/Images/technology_logo/aws_cloud.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                  <img
                    src="/Images/technology_logo/google_cloud.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="imageBox">
              <img
                src="/Images/SVG/Cloud-image-01.svg"
                alt=""
                data-aos="zoom-in"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="verticalSlide white security">
        <div className="row">
          <div className="col-lg-6">
            <div className="imageBox">
              <img src="/Images/Security.svg" alt="" data-aos="fade-right" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h-100 d-flex align-items-center">
              <div className="contentBox">
                <h4 className="homeCommonTitle" data-aos="fade-left">
                  Security First
                </h4>
                <p
                  className="homeCommonText"
                  data-aos="fade-left"
                  data-aos-delay="100"
                >
                  A guaranteed security on all your external-facing resources.
                </p>
                <div className="logoBox">
                  <img
                    src="/Images/technology_logo/cloudflare_logo.svg"
                    alt=""
                    data-aos="zoom-out-down"
                    data-aos-easing="ease-out-back"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="verticalSlide testimonials">
        <div className="container-fluid">
          <h4 className="homeCommonTitle" data-aos="fade-down">
            Testimonial
          </h4>
          <div className="row cardSwiper" data-aos="fade-up">
            {testimonalData.map((data, index) => (
              <TestimonialCard
                key={index}
                profileSrc={data.profileSrc}
                info={data.info}
                position={data.position}
                companyName={data.companyName}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="verticalSlide white clients">
        <div className="row">
          <div className="col-12">
            <h4 className="homeCommonTitle" data-aos="zoom-in">
              Clients
            </h4>
            <div className="logoContainer">
              {clientLogo.map((src, ind) => (
                <div
                  key={ind}
                  data-aos="zoom-in"
                  data-aos-delay="800"
                  className="logoBox"
                >
                  <Logo src={src.src} alt="client Logo" class="clientsLogo" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Contact/>
        <Footer/>
      </section>
      {/* <section className="verticalSlide footer white">
        <Contact aos="fade-down" />
        <Footer aos="fade-up" />
      </section> */}   
        
    </ReactFullpage.Wrapper>
  );
};

const testimonalData = [
  {
    info:
      "We are delighted to work with Logictrix infotech. For me just-in-time is meet prominent factor, the team always pulled-off extra efforts to deliever and maintain their quality as their benchmark.",
    profileSrc: "/Images/testimonials/niravshah.jpg",
    position: "DEPUTY-MAYOR(Surat)",
  },
  {
    info:
      "We are delighted to work with Logictrix infotech. For me just-in-time is meet prominent factor, the team always pulled-off extra efforts to deliever and maintain their quality as their benchmark.",
    profileSrc: "/Images/testimonials/niravshah.jpg",
    position: "DEPUTY-MAYOR(Surat)",
  },
  {
    info:
      "We are delighted to work with Logictrix infotech. For me just-in-time is meet prominent factor, the team always pulled-off extra efforts to deliever and maintain their quality as their benchmark.",
    profileSrc: "/Images/testimonials/niravshah.jpg",
    position: "DEPUTY-MAYOR(Surat)",
  },
];

const clientLogo = [
  {
    src: "/Images/clients/c-bjp.png",
  },
  {
    src: "/Images/clients/c-suratstore.png",
  },
  {
    src: "/Images/clients/c-yolofarm.png",
  },
  {
    src: "/Images/clients/c-mitron.png",
  },
  {
    src: "/Images/clients/c-oyo.png",
  },
  {
    src: "/Images/clients/c-paperdom.png",
  },
  {
    src: "/Images/clients/c-johnnytailor.png",
  },
  {
    src: "/Images/clients/c-bobbin.png",
  },
];

export default class Home extends Component {
  componentDidMount() {
    Aos.init({
      duration: 800,
    });

    $(".cardSwiper").slick({
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      adaptiveHeight: true,
      speed: 750,
    });
  }

  render() {
    return (
      <div className="main">
        {window.innerWidth > 992 ? (
          <ReactFullpage
            sectionSelector={".verticalSlide"}
            scrollingSpeed={250}
            scrollBar
            easing={"easeInOutCubic"}
            easingcss3={"ease"}
            navigationPosition={"right"}
            showActiveTooltip
            verticalCentered
            controlArrows
            responsiveWidth={992}
            render={({ state, fullpageApi }) => {
              return <FullPage />;
            }}
          />
        ) : (
          <FullPage />
          
        )}
      </div>
    );
  }
}
