import React, { Component } from "react";
import CareerCard from "../components/cards/CareerCard";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

class Career extends Component {
  render() {
    const cardData = [
      {
        position: "Node JS Developer",
        city: "surat",
        link: `${this.props.match.path}/nodeJsForm`,
      },
      {
        position: "IOS Developer",
        city: "surat",
        link: `/contactus`,
      },
      {
        position: "Java Developer",
        city: "surat",
        link: `/contactus`,
      },
    ];
    return (
      <>
        <main>
          <div className="carrer">
            <section className="homeBannerCommon">
              <div className="text-center">
                <h3 className="bannerHeading">Join Our Team</h3>
                <p className="bannerText">
                  Looking for People to INNOVATE a Better Tomorrow With Us!!
                </p>
              </div>
            </section>
            <section className="opening">
              <div className="container-fluid">
                <div className="text-center">
                  <h3 className="heading">Current Openings</h3>
                </div>
                <div className="row gy-3">
                  {cardData.map((data, indexedDB) => (
                    <CareerCard
                      key={indexedDB}
                      position={data.position}
                      city={data.city}
                      link={data.link}
                    />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </main>
        <Contact />
        <Footer />
      </>
    );
  }
}

export default Career;
