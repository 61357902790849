import React, { Component } from "react";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SolutionCardData from "../components/cards/SolutionCardData";
import SolutionCard from "../components/cards/SolutionCard";
import Lottie from "react-lottie";

export default class Solution extends Component {
  render() {
    const lottieOption = {
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: "/Animations/Solution_Banner.json",
      speed: 1,
    };

    return (
      <>
        <main>
          <div className="solution">
            <section className="homeBannerCommon">
              <div className="row">
                <div className="col-lg-6">
                  <div className="w-100 contentBox">
                    <h3 className="bannerHeading">
                      “Name it! We make it happen.”
                    </h3>
                    <p className="bannerText">
                      We deliver all the cutting-edge solutions with our fully
                      potential team of full-stack developers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="lottie">
                    <Lottie options={lottieOption} />
                  </div>
                </div>
              </div>
            </section>

            <section className="platform">
              <div className="container-fluid">
                <div className="row g-3">
                  {SolutionCardData.map((data, index) => (
                    <SolutionCard
                      key={index}
                      cardImgSrc={data.src}
                      info={data.info}
                      pricing ={data.pricing}
                      link={data.link}
                    />
                  ))}
                </div>
              </div>
            </section>
          </div>
        </main>
        <Contact />
        <Footer />
      </>
    );
  }
}
