import React from "react";
import { Link } from "react-router-dom";

const CareerCard = (props) => {
  return (
    <div className="col-md-4">
      <div className="card">
        <div>
          <h5 className="positionHeading">{props.position}</h5>
          <p className="cityName">{props.city}</p>
        </div>
        <Link className="button fullBlue" to={`${props.link}`}>
          See Detail
        </Link>
      </div>
    </div>
  );
};

export default CareerCard;
