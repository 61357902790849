import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";

export default class Footer extends Component {
  render() {
    return (
      <footer data-aos={this.props.aos}>
        <div className="container-fluid">
          <div className="row gy-4 gy-lg-0">
            <div className="col-lg-3 col-12 col-sm-6">
              <Logo
                src="Images/SVG/Logictrix Logo/Logictrix-Logo-White.svg"
                class="footerLogo"
                alt="brandLogo"
              />
            </div>
            <div className="col-lg-3 col-12 col-sm-6">
              <ul className="list">
                <li className="listItem">Contact us</li>
                <li className="listItem">Email:</li>
                <li className="listItem">
                  <a href="mailto:hello@logictrixinfotech.com">
                    hello@logictrixinfotech.com
                  </a>
                </li>
                <li className="listItem">Phone</li>
                <li className="listItem">
                  <a href="tel:8799622322">+91-8799622322</a>
                </li>
                <li className="listItem social">
                  <a href="https://www.facebook.com/LogictrixInfotech/">
                    <Logo
                      src="Images/SVG/Social/ic_facebook.svg"
                      alt="facebook"
                    />
                  </a>
                  <a href="https://www.instagram.com/logictrixinfotech">
                    <Logo
                      src="Images/SVG/Social/ic_instagram.svg"
                      alt="facebook"
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/logictrix-infotech/">
                    <Logo src="Images/SVG/Social/linkedin.svg" alt="facebook" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-12 col-sm-6">
              <ul className="list">
                <li className="listItem">Services</li>
                <li className="listItem">Web Development</li>
                <li className="listItem">Mobile Applications</li>
                <li className="listItem">
                  Business Intelligence & Data Warehousing
                </li>
                <li className="listItem">Staff Augmentation</li>
                <li className="listItem">Security</li>
                <li className="listItem">Cloud Services</li>
                <li className="listItem">Internet of Things</li>
                <li className="listItem">AI & Automation</li>
                <li className="listItem">Product Engineering</li>
              </ul>
            </div>
            <div className="col-lg-3 col-12 col-sm-6">
              <ul className="list">
                <li className="listItem">Company</li>
                <li className="listItem">
                  <Link to="/solution"> Solution</Link>
                </li>
                <li className="listItem">
                  <Link to="/ourwork"> Our Work </Link>
                </li>
                {/* <li className="listItem">
                  <Link to="/blogs"> Blogs </Link>
                </li> */}
                <li className="listItem">
                  <Link to="/career"> Career </Link>
                </li>
                <li className="listItem">
                  <Link to="/about"> About </Link>
                </li>
                <li className="listItem">
                  <Link to="/contactus"> Contact </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row my-4">
            <hr className="verticalLine" />
            <div className="col-md-4 col-12">
              <p className="ownerShipText">
                ©2024 Logictrix Infotech Pvt. Ltd.
              </p>
            </div>
            <div className="col-md-2 col-6">
              <p className="ownerShipText">
                <Link to="/Terms&Conditions"> Terms & Conditions </Link>
              </p>
            </div>
            <div className="col-md-2 col-6">
              <p className="ownerShipText">
                <Link to="/PrivacyPolicy"> Privacy Policy </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
