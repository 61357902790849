import React from "react";

const TestimonialCard = (props) => {
  return (
    <div className="col-lg-3 col-md-4 swiper-slide">
      <div className="card">
        <div className="profileHeader">
          <img src="Images/SVG/Quots.svg" className="quots" alt="Quots" />
          <p className="text">{props.info}</p>
          <img
            src="Images/SVG/Quots.svg"
            className="quots"
            style={{
              transform: "rotate(180deg)",
              alignSelf: "flex-end",
              marginTop: "-1.8vw",
            }}
            alt="Quots"
          />
        </div>
        <div className="profileBox">
          <img
            src={props.profileSrc}
            alt="profilePhoto"
            className="profilePhoto"
          />
          <div className="profileDetail">
            <h5 className="name">{props.position}</h5>
            <p className="companyName">{props.companyName}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
