import React from 'react';
import { Box, Typography } from '@mui/material';

const Iot = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Transforming Industries with Internet of Things (IoT) Solutions
      </Typography>
      
      <p>In an era of unprecedented connectivity, the Internet of Things (IoT) is redefining how businesses operate and interact with the physical world. At Logictrix Infotech, we're at the forefront of this IoT revolution, delivering cutting-edge solutions that connect devices, generate valuable data, and create smarter, more efficient systems across industries.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Potential of IoT
      </Typography>      
      <p>IoT is rapidly reshaping the business landscape. With the global IoT market projected to reach $1,386.06 billion by 2026, the potential for growth and innovation is immense. Whether you're a startup or an established enterprise, leveraging IoT can:</p>
      
      <ul>
        <li>Enhance operational efficiency through real-time monitoring and automation</li>
        <li>Improve decision-making with data-driven insights from connected devices</li>
        <li>Create new revenue streams through smart, connected products and services</li>
        <li>Reduce downtime and maintenance costs with predictive maintenance</li>
        <li>Enhance customer experiences through personalized, responsive services</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's IoT Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just implement IoT devices; we create comprehensive ecosystems that drive tangible business outcomes. Our IoT offerings include:</p>
      
      <ol>
        <li><strong>IoT Strategy and Consulting:</strong> We help you develop a robust IoT strategy aligned with your business goals and industry specifics.</li>
        <li><strong>IoT Platform Development:</strong> We build scalable, secure IoT platforms that connect devices, collect data, and enable seamless communication.</li>
        <li><strong>Smart Device Development:</strong> We design and develop smart, connected devices tailored to your specific needs and use cases.</li>
        <li><strong>IoT Data Analytics:</strong> We implement advanced analytics solutions that turn IoT data into actionable insights.</li>
        <li><strong>IoT Security:</strong> We ensure the security of your IoT ecosystem with robust encryption, authentication, and threat detection mechanisms.</li>
        <li><strong>Industrial IoT (IIoT):</strong> We specialize in IIoT solutions that optimize manufacturing processes, enable predictive maintenance, and enhance supply chain visibility.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our IoT Development Process
      </Typography>
      <ol>
        <li><strong>Discovery:</strong> We analyze your business processes and identify areas where IoT can create the most value.</li>
        <li><strong>Strategy:</strong> We develop a comprehensive IoT strategy tailored to your business objectives and industry requirements.</li>
        <li><strong>Architecture Design:</strong> We design a scalable, secure IoT architecture that integrates seamlessly with your existing systems.</li>
        <li><strong>Development:</strong> Our experts build custom IoT solutions using cutting-edge technologies and best practices.</li>
        <li><strong>Testing:</strong> We rigorously test our IoT solutions to ensure reliability, security, and performance.</li>
        <li><strong>Deployment:</strong> We carefully implement the IoT solution, ensuring minimal disruption to your operations.</li>
        <li><strong>Monitoring & Optimization:</strong> We provide ongoing support, monitoring the IoT ecosystem and optimizing it for peak performance.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for IoT?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> Our team of IoT specialists brings years of experience across various industries and technologies.</li>
        <li><strong>End-to-End Solutions:</strong> We offer comprehensive IoT services, from strategy to implementation to ongoing support.</li>
        <li><strong>Scalability:</strong> Our IoT solutions are designed to grow with your business, easily adapting to changing needs and increasing device numbers.</li>
        <li><strong>Security-First Approach:</strong> We prioritize security in all our IoT solutions, ensuring your data and devices are protected.</li>
        <li><strong>Innovation Focus:</strong> We stay at the cutting edge of IoT technologies, constantly incorporating the latest advancements into our solutions.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Embark on Your IoT Journey?
      </Typography>
      
      <p>In today's interconnected world, IoT is no longer a futuristic concept—it's a critical driver of business innovation and efficiency. At Logictrix Infotech, we're committed to helping businesses harness the full potential of IoT to transform operations, create new opportunities, and stay ahead in the digital age.</p>
      
      <p>Don't let your business fall behind in the IoT revolution. Contact Logictrix Infotech today, and let's explore how we can leverage IoT to connect your business to a world of new possibilities.</p>
      
      <p>Remember, in the Internet of Things, every connection is an opportunity. With Logictrix Infotech, you're not just connecting devices—you're connecting to the future of your industry!</p>
    </Box>
  );
};

export default Iot;