import React from 'react';
import { Box, Typography } from '@mui/material';

const BusinessIntelligence = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Unlocking Business Potential with Business Intelligence and Data Warehousing
      </Typography>
      
      <p>In today's data-driven business landscape, the ability to extract meaningful insights from vast amounts of information is no longer a luxury—it's a necessity. At Logictrix Infotech, we're leading the charge in Business Intelligence (BI) and Data Warehousing solutions, empowering businesses to make smarter decisions and drive growth through data-driven strategies.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Power of Business Intelligence and Data Warehousing
      </Typography>      
      <p>With the global Business Intelligence market expected to reach $33.3 billion by 2025, the potential for transformation is immense. Whether you're a growing startup or an established enterprise, leveraging BI and Data Warehousing can:</p>
      
      <ul>
        <li>Provide a single source of truth for all business data</li>
        <li>Enable faster, more informed decision-making</li>
        <li>Identify new business opportunities and trends</li>
        <li>Improve operational efficiency and reduce costs</li>
        <li>Enhance customer understanding and satisfaction</li>
      </ul>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's BI and Data Warehousing Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just implement BI tools; we create comprehensive data ecosystems that drive real business value. Our BI and Data Warehousing offerings include:</p>
      
      <ol>
        <li><strong>Data Warehouse Design and Implementation:</strong> We architect and build robust data warehouses that consolidate data from multiple sources into a single, coherent structure.</li>
        <li><strong>ETL (Extract, Transform, Load) Processes:</strong> Our ETL solutions ensure that your data is clean, consistent, and ready for analysis.</li>
        <li><strong>Business Intelligence Dashboards:</strong> We create intuitive, interactive dashboards that provide real-time insights into your key performance indicators.</li>
        <li><strong>Predictive Analytics:</strong> Our advanced analytics models help you forecast trends and make proactive business decisions.</li>
        <li><strong>Data Visualization:</strong> We transform complex data into clear, compelling visual stories that drive action.</li>
        <li><strong>Self-Service BI Tools:</strong> We implement and customize self-service BI platforms that empower your team to explore data independently.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our BI and Data Warehousing Development Process
      </Typography>
      <ol>
        <li><strong>Requirements Analysis:</strong> We work closely with you to understand your business needs and data landscape.</li>
        <li><strong>Data Architecture Design:</strong> We design a scalable and efficient data architecture tailored to your specific requirements.</li>
        <li><strong>Data Integration:</strong> We integrate data from various sources, ensuring data quality and consistency.</li>
        <li><strong>Warehouse Implementation:</strong> We build and optimize your data warehouse for performance and scalability.</li>
        <li><strong>BI Tool Selection and Implementation:</strong> We help you choose and implement the right BI tools for your needs.</li>
        <li><strong>Dashboard and Report Creation:</strong> We develop custom dashboards and reports that deliver actionable insights.</li>
        <li><strong>Training and Support:</strong> We provide comprehensive training and ongoing support to ensure you get the most out of your BI solution.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Choose Logictrix Infotech for BI and Data Warehousing?
      </Typography>
      <ol>
        <li><strong>Expertise:</strong> Our team of BI specialists and data engineers bring years of experience across various industries.</li>
        <li><strong>Customization:</strong> We tailor our solutions to your unique business needs and data environment.</li>
        <li><strong>Scalability:</strong> Our solutions are designed to grow with your business, handling increasing data volumes and complexity.</li>
        <li><strong>Data Security:</strong> We prioritize data security and compliance in all our BI and Data Warehousing solutions.</li>
        <li><strong>Continuous Innovation:</strong> We stay at the forefront of BI and Data Warehousing technologies, constantly incorporating the latest advancements into our solutions.</li>
      </ol>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Harness the Power of Your Data?
      </Typography>
      
      <p>In an increasingly competitive business environment, the ability to leverage data for strategic decision-making is crucial. At Logictrix Infotech, we're committed to helping businesses unlock the full potential of their data through cutting-edge BI and Data Warehousing solutions.</p>
      
      <p>Don't let valuable insights hide in your data. Contact Logictrix Infotech today, and let's explore how we can transform your data into a powerful asset that drives your business forward.</p>
      
      <p>Remember, in the world of business, knowledge is power—and with Logictrix Infotech's BI and Data Warehousing solutions, you'll have the knowledge you need to power your success!</p>
    </Box>
  );
};

export default BusinessIntelligence;