import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const CloudServices = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '80%', margin: 'auto', marginTop: '50px' }}>
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
      Elevate Your Business with Cutting-Edge Cloud Services
      </Typography>
      
      <p>In the digital age, the cloud is not just a technological advancement—it's a business imperative. At Logictrix Infotech, we're passionate about harnessing the transformative power of cloud computing to propel businesses into a future of unprecedented scalability, efficiency, and innovation.</p>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      The Cloud Revolution: More Than Just Storage
      </Typography>      
      <p>With the global cloud computing market projected to reach $832.1 billion by 2025, the cloud is reshaping business landscapes across industries. Embracing cloud services can:</p>
      
      <List>
        <ListItem>
          <ListItemText primary="• Dramatically reduce IT infrastructure costs" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Enable unparalleled business agility and scalability" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Enhance collaboration and productivity across teams" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Strengthen data security and disaster recovery capabilities" />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Accelerate innovation and time-to-market for new products" />
        </ListItem>
      </List>
      
      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Logictrix Infotech's Comprehensive Cloud Solutions
      </Typography>
      
      <p>At Logictrix Infotech, we don't just migrate you to the cloud—we architect comprehensive cloud ecosystems that drive tangible business outcomes. Our cloud services portfolio includes:</p>
      
      <List>
        <ListItem>
          <ListItemText 
            primary="1. Cloud Strategy and Consulting" 
            secondary="We craft tailored cloud adoption strategies that align with your business objectives and industry dynamics."
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="2. Multi-Cloud and Hybrid Cloud Solutions" 
            secondary="We design and implement flexible cloud environments that leverage the best of multiple cloud platforms."
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="3. Cloud Migration and Modernization" 
            secondary="We seamlessly transition your legacy systems to cloud-native architectures, ensuring minimal disruption."
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="4. Cloud-Native Application Development" 
            secondary="We build scalable, resilient applications optimized for cloud environments using microservices and containerization."
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="5. Cloud Security and Compliance" 
            secondary="We implement robust security measures and ensure your cloud infrastructure adheres to industry regulations."
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="6. CloudOps and DevOps" 
            secondary="We streamline your cloud operations and implement DevOps practices for continuous integration and delivery."
          />
        </ListItem>
      </List>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Our Cloud Transformation Approach
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Discovery and Assessment" secondary="We analyze your current IT landscape and business needs." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Strategy Formulation" secondary="We develop a comprehensive cloud adoption roadmap." />
        </ListItem>
        <ListItem>
          <ListItemText primary="3. Architecture Design" secondary="We design a scalable, secure cloud architecture." />
        </ListItem>
        <ListItem>
          <ListItemText primary="4. Migration and Implementation" secondary="We execute the migration with minimal business disruption." />
        </ListItem>
        <ListItem>
          <ListItemText primary="5. Optimization and Management" secondary="We continuously optimize your cloud environment for performance and cost-efficiency." />
        </ListItem>
        <ListItem>
          <ListItemText primary="6. Training and Support" secondary="We empower your team with the skills needed to leverage your new cloud capabilities." />
        </ListItem>
      </List>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Why Logictrix Infotech for Your Cloud Journey?
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="• Cloud Expertise" secondary="Our certified cloud specialists bring deep expertise across major cloud platforms." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Vendor-Agnostic Approach" secondary="We recommend the best cloud solutions for your needs, not for our partnerships." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• End-to-End Services" secondary="From strategy to implementation to ongoing management, we're with you every step of the way." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Innovation Focus" secondary="We leverage cutting-edge cloud technologies to drive innovation in your business." />
        </ListItem>
        <ListItem>
          <ListItemText primary="• Cost Optimization" secondary="We help you maximize the ROI of your cloud investments through strategic optimization." />
        </ListItem>
      </List>

      <Typography variant="h5" sx={{ marginTop: '20px', fontWeight:'bold' }}>
      Ready to Soar into the Cloud?
      </Typography>
      
      <p>In today's fast-paced digital landscape, the cloud isn't just an option—it's the foundation for future business success. At Logictrix Infotech, we're excited to guide you on your transformative cloud journey.</p>
      
      <p>Don't let your business be grounded by outdated IT infrastructure. Contact Logictrix Infotech today, and let's explore how our cloud services can elevate your operations, boost innovation, and propel your business to new heights.</p>
      
      <p>Remember, in the world of modern business, the sky's no longer the limit—it's your new playground. With Logictrix Infotech's cloud solutions, you're ready to play at a whole new level!</p>
    </Box>
  );
};

export default CloudServices;