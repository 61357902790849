import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import Backdrop from "./Backdrop";

export default class Navbar extends Component {
  state = {
    sideNav: false,
    reload: false,
  };

  sideNavOpen = () => {
    this.setState({ sideNav: !this.state.sideNav });
  };

  render() {
    var classes = [];
    if (this.state.sideNav) {
      classes = ["backdrop"];
    }

    return (
      <header>
        <nav className="navbar white navbar-expand-lg">
          <NavLink className="navbar-brand" to="/home">
            <Logo
              src="/Images/SVG/Logictrix Logo/Logictrix-Logo-Gray.svg"
              className="logoGray"
              alt="navLogo"
            />
          </NavLink>
          <div
            className={
              this.state.sideNav ? "sideMenuIcon active" : "sideMenuIcon"
            }
            onClick={this.sideNavOpen}
          >
            <div className="iconStep"></div>
            <div className="iconStep"></div>
            <div className="iconStep"></div>
          </div>
          <ul className="ms-auto navbar-nav navList">
            <li className="nav-item">
              <NavLink
                className="navLink homePage"
                activeClassName="navLinkActive"
                to="/home"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/solution"
              >
                Solutions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/ourwork"
              >
                Our Work
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/blogs"
              >
                Blogs
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/career"
              >
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                to="/contactus"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        <Backdrop class={classes} onClickFunction={this.sideNavOpen} />
        <nav
          style={
            this.state.sideNav
              ? { transform: "translateX(0)" }
              : { transform: "translateX(-100%)" }
          }
          className="sideNavbar"
        >
          <ul className="mobNavList">
            <li className="nav-item">
              <NavLink
                className="navLink homePage"
                activeClassName="navLinkActive"
                onClick={this.sideNavOpen}
                to="/home"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                onClick={this.sideNavOpen}
                to="/solution"
              >
                Solutions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navLinkActive"
                className="navLink"
                onClick={this.sideNavOpen}
                to="/ourwork"
              >
                Our Work
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                onClick={this.sideNavOpen}
                to="/blogs"
              >
                Blogs
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                onClick={this.sideNavOpen}
                to="/career"
              >
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navLinkActive"
                className="navLink"
                onClick={this.sideNavOpen}
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navLink"
                activeClassName="navLinkActive"
                onClick={this.sideNavOpen}
                to="/contactus"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}
