import React, { Component } from "react";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Lottie from "react-lottie";

export default class About extends Component {
  render() {
    const lottieOption = {
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: "/Animations/Process-infinite_update.json",
      speed: 1,
    };

    return (
      <>
        <main>
          <div className="about">
            <section className="homeBannerCommon">
              <div className="text-center">
                <h3 className="bannerHeading">
                  Logictrix Infotech is a technological partner you are looking
                  for.
                </h3>
              </div>
            </section>

            <section className="info">
              <div className="container-fluid">
                <p className="text">
                  We create a world-class customized business software programs
                  and applications. We are a team of technical enthusiasts, who
                  believe in bringing a revolutionary impact in the industry. No
                  matter what is the scale of your business we will help you
                  take it to the next level.
                </p>
              </div>
            </section>

            <section className="mission">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="/Images/mission svg.svg"
                      className="missionImage"
                      alt="missionImage"
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="innerBox">
                      <div className="infoBox">
                        <h5 className="heading">Vision</h5>
                        <p className="text">
                          We seek to make the operation of a business more
                          accessible and cost-effective for small business
                          owners and independent retailers, offering a
                          self-serving platform or tech integration to their
                          business to thrive.
                        </p>
                      </div>
                      <div className="infoBox">
                        <h5 className="heading">Mission</h5>
                        <p className="text">
                          To ensure everyone can use technology to streamline
                          and organize their work with creativity and
                          innovation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="lottie aboutLottie">
              <div className="lottiePlayer">
                <Lottie options={lottieOption} />
              </div>
            </section>
          </div>
        </main>
        <Contact />
        <Footer />
      </>
    );
  }
}
